<div class="container-fluid">
  <app-header></app-header>
  <section class="section background">
    <div class="row">
      <div class="col-sm-12 text-center">
        <h1 class="text-center section_title">Noire Nail Bar</h1>
        <hr />
        <br />
        <p>
          Noire Nail Bar is a brand new nail salon, located in Chamblee, GA,
          that offers nail, make-up, waxing, eyelash extentions, and botox
          services. The manager, Christine, wanted a simple and easy to use
          website that also has a little pizzaz (so to speak). I worked with her
          over a few weeks while I was working on it to make sure she was not
          only involved in the process but also liked everything that I was
          doing - step by step.
        </p>
        <p>
          Check out the site here:
          <a href="http://www.noirenailbaratl.com" target="_blank"
            >noirenailbaratl.com</a
          >
        </p>
        <br /><br />
        <div class="row">
          <div class="col-sm-12 text-center">
            <h3>Monitor</h3>
            <hr />
          </div>
        </div>
        <br /><br />
        <div class="row">
          <div class="col-sm-12 text-center hover01">
            <figure>
              <img src="../../../../assets/images/projects/nnb/nnb_monitor.png" class="monitor" />
            </figure>
          </div>
        </div>
        <br /><br />
        <br /><br />
        <br /><br />
        <br /><br />
        <div class="row">
          <div class="col-sm-12 text-center">
            <h3>Laptop</h3>
            <hr />
          </div>
        </div>
        <br /><br />
        <div class="row">
          <div class="col-sm-12 text-center hover01">
            <figure>
              <img src="../../../../assets/images/projects/nnb/nnb_laptop.png" class="laptop" />
            </figure>
          </div>
        </div>
        <br /><br />
        <br /><br />
        <br /><br />
        <br /><br />
        <div class="row">
          <div class="col-sm-12 text-center">
            <h3>Tablet</h3>
            <hr />
          </div>
        </div>
        <br /><br />
        <div class="row">
          <div class="col-sm-12 text-center hover01">
            <figure>
              <img src="../../../../assets/images/projects/nnb/nnb_tablet.png" class="tablet" />
            </figure>
          </div>
        </div>
        <br /><br />
        <br /><br />
        <br /><br />
        <br /><br />
        <div class="row">
          <div class="col-sm-12 text-center">
            <h3>Phone</h3>
            <hr />
          </div>
        </div>
        <br /><br />
        <div class="row">
          <div class="col-sm-12 text-center hover01">
            <figure>
              <img src="../../../../assets/images/projects/nnb/nnb_mobile.png" class="mobile" />
            </figure>
          </div>
        </div>
        <br /><br />
        <br /><br />
        <br /><br />
        <span class="tag">HTML</span><span class="tag">CSS</span
        ><span class="tag">Bootstrap</span><span class="tag">JS</span
        ><span class="tag">JQuery</span> <br /><br />
        <br /><br />
      </div>
    </div>
  </section>
  <app-footer></app-footer>
</div>
