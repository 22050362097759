<div class="container-fluid">
  <app-header></app-header>
  <div class="row">
    <div class="col-sm-12">
      <div class="section background">
        <blockquote class="text-center">
          All that we are is a result of what we thought.
          <cite>Buddha</cite>
        </blockquote>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-8">
      <div class="section background">
        <h1 class="text-center section_title">ABOUT</h1>
        <hr />
        <br />
        <div class="row">
          <div class="col-md-4 text-center">
            <div class="row">
              <div class="col-sm-12">
                <img src="../../../assets/images/me.jpg" style="width: 100%" />
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-sm-12">
                <img
                  src="../../../assets/images/boys_sunset.jpg"
                  style="width: 100%"
                />
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-sm-12">
                <img
                  src="../../../assets/images/gc.jpg"
                  style="width: 100%"
                />
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <p>
              I am a passionate software engineer who loves to code. I graduated
              from KSU - go Owls! - in spring of 2013. Very driven and talented
              in many areas: from the frontend to the backend and databases. I
              have been developing in Angular for many years now and that is my
              favorite framework at the moment. I am very driven and talented in
              many areas: from creating or modifying images in Photoshop to web
              design to backend development. I am determined to learn and grow
              further in my career as a software engineer.
            </p>
            <br />
            <p>
              Since I'm originally from Douglasville, GA, I am no stranger to
              the Atlanta area. I consider myself to be an ATLien and love all
              things ATL.
            </p>
            <br />
            <p>
              When I am not working, I love working out and staying in
              shape. I have completed two full marathons and more than a dozen
              half marathons and countless other distances since 2011. I am very
              driven about my health and my career. I love going to the gym to
              lift as I am a retired runner now. I love attending tech meetups
              and conferences when I get the chance to stay in touch and network.
              I also love socializing and going out and meeting new people.
            </p>
            <br />
            <h4>Fun Facts:</h4>
            <ul>
              <li>
                My two boys (pictured) are my world. I love dogs and my favorite
                wild animals are big cats of any sort.
              </li>
              <li>
                I love the great outdoors, nothing like fresh air!
              </li>
              <li>
                Huge horror/sci-fi movie fan. I have Freddy and Jason tattood
                behind my ears.
              </li>
              <li>Lefty for life.</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="section background">
            <h1 class="text-center section_title">PROJECTS</h1>
            <hr />
            <br />
            <p class="text-center">Click each monitor for more details.</p>
            <br />
            <br />
            <div class="row">
              <div class="col-sm-6 text-center hover01">
                <a [routerLink]="['/projects/noire-nail-bar']">
                  <figure>
                    <img
                      src="../../../assets/images/projects/nnb/nnb_monitor.png"
                      style="width: 100%"
                    />
                  </figure>
                </a>
              </div>
              <div class="col-sm-6 text-center hover01">
                <a [routerLink]="['/projects/otg']">
                  <figure>
                    <img
                      src="../../../assets/images/projects/otg/otg_monitor.png"
                      style="width: 100%"
                    />
                  </figure>
                </a>
              </div>
            </div>
            <br /><br />
            <div class="row">
              <div class="col-sm-6 text-center hover01">
                <a [routerLink]="['/projects/powwowhr']">
                  <figure>
                    <img
                      src="../../../assets/images/projects/powwow/pw_monitor.png"
                      style="width: 100%"
                    />
                  </figure>
                </a>
              </div>
              <div class="col-sm-6 text-center hover01">
                <a [routerLink]="['/projects/bluetube']">
                  <figure>
                    <img
                      src="../../../assets/images/projects/bluetube/afl_monitor.png"
                      style="width: 100%"
                    />
                  </figure>
                </a>
              </div>
            </div>
            <br /><br />
            <div class="row">
              <div class="col-sm-3"></div>
              <div class="col-sm-6 text-center hover01">
                <a [routerLink]="['/projects/ptc']">
                  <figure>
                    <img
                      src="../../../assets/images/projects/ptc/ptc_monitor.png"
                      style="width: 100%"
                    />
                  </figure>
                </a>
              </div>
              <div class="col-sm-3"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-12">
          <div class="section background">
            <h1 class="text-center section_title">SIDE PROJECTS</h1>
            <hr />
            <br />
            <div class="row">
              <div class="col-sm-2"></div>
              <div class="col-sm-8">
                <div class="text-center">
                  <img
                    src="../../../assets/images/yummyeats_logo.png"
                    style="width: 50%"
                  />
                </div>
                <br />
                <p>
                  Yummy Eats ATL is a blog I created to show off some of my
                  favorite authentic restaurants in the Atlanta area. I am
                  shooting for more of a Buford Hwy vibe, and since I live in
                  Duluth, it's been no trouble at all finding great spots.
                </p>
                <p>
                  <a href="https://yummyeatsatl.fun/" target="_blank"
                    >https://yummyeatsatl.fun/</a
                  >
                </p>
              </div>
              <div class="col-sm-2"></div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <div class="col-sm-4">
      <div class="row">
        <div class="col-12">
          <div class="section background">
            <h1 class="text-center section_title">CURRENT WORKPLACE</h1>
            <hr />
            <br />
            <div class="row">
              <div class="col-sm-12 text-center">
                <a href="https://go.mycarrier.io/" target="_blank"
                  ><img src="../../../assets/images/PNG-MC-COLOR-Logo.webp"
                /></a>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-sm-12">
                <p>
                  I wanted to show off the company I work for, not only to say
                  that I am proudly NOT in job market, but because I seriously
                  LOVE my company!! I love MyCarrier, and working with an
                  awesome team!
                </p>
                <p>
                  MyCarrier is a startup located in Scottsdale, AZ - I'm happy
                  to work remotely and I loved that I got the chance to visit last December!
                  I am thrilled to work for a small company again, I really love
                  the tight knit vibes. One of my goals was to work for a SaaS
                  based company that has an awesome product and proud to say
                  MyCarrier checks all the boxes!
                </p>
                <p>
                  I'm super passionate about technology and I'm looking forward
                  to learning and growing my career as a software engineer at
                  MyCarrier.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="section background">
            <h1 class="text-center section_title">SKILLS</h1>
            <hr />
            <br />
            <h4 class="text-center">Backend Development:</h4>
            <ul>
              <li>ASP.NET (MVC & Web Forms)</li>
              <li>C#</li>
              <li>VB.NET</li>
              <li>LINQ</li>
              <li>Entity Framework</li>
              <li>SQL Server</li>
            </ul>
            <h4 class="text-center">Frontend Development:</h4>
            <ul>
              <li>HTML / CSS</li>
              <li>Javascript</li>
              <li>AngularJS / Angular 2-18</li>
              <li>Node.js</li>
              <li>JQuery</li>
              <li>Bootstrap</li>
              <li>PHP</li>
            </ul>
            <h4 class="text-center">Tools:</h4>
            <ul>
              <li>Visual Studio 2010-2022/Code</li>
              <li>NPM package manager</li>
              <li>Postman</li>
              <li>GIT / TFS</li>
              <li>SQL Server Management Studio</li>
              <li>Photoshop</li>
              <li>Notepad++</li>
              <li>Microsoft Office</li>
              <li>Koala</li>
              <li>Fork</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="section background">
            <h1 class="text-center section_title">CONTACT</h1>
            <hr />
            <br />
            <p class="text-center">
              Feel free to drop a message and I will contact you back as soon as
              possible.
            </p>

            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12">
                <br />
                <br />
                <form
                  [formGroup]="contactForm"
                  (ngSubmit)="submit(contactForm.value)"
                  novalidate
                >
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                      <div class="form-group">
                        <label for="name" class="control-label label-required"
                          >Name:</label
                        >
                        <input
                          type="text"
                          class="form-control custom_input"
                          id="name"
                          name="name"
                          formControlName="name"
                          required
                        />
                        <div
                          *ngIf="
                            this.contactForm.controls.name.invalid &&
                            (this.contactForm.controls.name.dirty ||
                              this.contactForm.controls.name.touched)
                          "
                        >
                          <div
                            *ngIf="
                              this.contactForm.controls.name.errors.required
                            "
                            class="text-danger"
                          >
                            <p>Name is required</p>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="email" class="control-label label-required"
                          >Email:</label
                        >
                        <input
                          type="email"
                          class="form-control custom_input"
                          id="email"
                          name="email"
                          formControlName="email"
                          required
                        />
                        <div
                          *ngIf="
                            this.contactForm.controls.email.invalid &&
                            (this.contactForm.controls.email.dirty ||
                              this.contactForm.controls.email.touched)
                          "
                        >
                          <div
                            *ngIf="
                              this.contactForm.controls.email.errors.required
                            "
                            class="text-danger"
                          >
                            <p>Email is required</p>
                          </div>
                          <div
                            *ngIf="this.contactForm.controls.email.errors.email"
                            class="text-danger"
                          >
                            <p>Email is not valid</p>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label
                          for="message"
                          class="control-label label-required"
                          >Message:</label
                        >
                        <textarea
                          rows="8"
                          cols="5"
                          class="form-control custom_input"
                          id="message"
                          name="message"
                          formControlName="message"
                          required
                        ></textarea>
                        <div
                          *ngIf="
                            this.contactForm.controls.message.invalid &&
                            (this.contactForm.controls.message.dirty ||
                              this.contactForm.controls.message.touched)
                          "
                        >
                          <div
                            *ngIf="
                              this.contactForm.controls.message.errors.required
                            "
                            class="text-danger"
                          >
                            <p>Message is required</p>
                          </div>
                        </div>
                      </div>
                      <input
                        type="hidden"
                        name="_subject"
                        value="Contact form submitted"
                      />
                      <input
                        type="hidden"
                        name="_after"
                        value="https://tanyamott.com"
                      />
                      <input type="hidden" name="_honeypot" value="" />
                      <input type="hidden" name="_confirmation" value="" />
                      <button
                        type="submit"
                        class="btn btn_custom float-right"
                        [disabled]="!contactForm.valid"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
