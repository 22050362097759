import { Component, OnInit } from '@angular/core';
import {
  faGithub,
  faLinkedin,
  faMedium,
  faTwitterSquare,
  faDev
} from "@fortawesome/free-brands-svg-icons";
import {
  faMapPin
} from "@fortawesome/free-solid-svg-icons"

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  faGithub = faGithub;
  faLinkedIn = faLinkedin;
  faMedium = faMedium;
  faTwitter = faTwitterSquare;
  faMapPin = faMapPin;
  faDev = faDev;

  constructor() { }

  ngOnInit(): void {
  }

}
