import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { BluetubeComponent } from './components/projects/bluetube/bluetube.component';
import { NoireNailBarComponent } from './components/projects/noire-nail-bar/noire-nail-bar.component';
import { OnTheGoComponent } from './components/projects/on-the-go/on-the-go.component';
import { PowWowHrComponent } from './components/projects/pow-wow-hr/pow-wow-hr.component';
import { PtcComponent } from './components/projects/ptc/ptc.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'projects/bluetube',
    component: BluetubeComponent
  },
  {
    path: 'projects/noire-nail-bar',
    component: NoireNailBarComponent
  },
  {
    path: 'projects/otg',
    component: OnTheGoComponent
  },
  {
    path: 'projects/powwowhr',
    component: PowWowHrComponent
  },
  {
    path: 'projects/ptc',
    component: PtcComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
