<div class="container-fluid">
  <app-header></app-header>
  <section class="section background">
    <div class="row">
      <div class="col-sm-12 text-center">
        <h1 class="text-center section_title">Arts for Learning</h1>
        <hr />
        <br />
        <p>
          I learned the concept of Responsive Web Design at Bluetube Interactive
          (bought out by Perficient Digital) and Arts of Learning is one of the
          Photoshop "cut-ups" that I created from a Photoshop file. I created
          the site in Foundation using HTML/CSS and Javascript for the
          slideshow.
        </p>

        <br /><br />
        <div class="row">
          <div class="col-sm-12 text-center">
            <h3>Monitor</h3>
            <hr />
          </div>
        </div>
        <br /><br />
        <div class="row">
          <div class="col-sm-12 text-center hover01">
            <figure>
              <img
                src="../../../../assets/images/projects/bluetube/afl_monitor.png"
                class="monitor"
              />
            </figure>
          </div>
        </div>
        <br /><br />
        <br /><br />
        <br /><br />
        <br /><br />
        <div class="row">
          <div class="col-sm-12 text-center">
            <h3>Laptop</h3>
            <hr />
          </div>
        </div>
        <br /><br />
        <div class="row">
          <div class="col-sm-12 text-center hover01">
            <figure>
              <img
                src="../../../../assets/images/projects/bluetube/afl_laptop.png"
                class="laptop"
              />
            </figure>
          </div>
        </div>
        <br /><br />
        <br /><br />
        <br /><br />
        <br /><br />
        <div class="row">
          <div class="col-sm-12 text-center">
            <h3>Tablet</h3>
            <hr />
          </div>
        </div>
        <br /><br />
        <div class="row">
          <div class="col-sm-12 text-center hover01">
            <figure>
              <img
                src="../../../../assets/images/projects/bluetube/afl_tablet.png"
                class="tablet"
              />
            </figure>
          </div>
        </div>
        <br /><br />
        <br /><br />
        <br /><br />
        <br /><br />
        <div class="row">
          <div class="col-sm-12 text-center">
            <h3>Phone</h3>
            <hr />
          </div>
        </div>
        <br /><br />
        <div class="row">
          <div class="col-sm-12 text-center hover01">
            <figure>
              <img
                src="../../../../assets/images/projects/bluetube/afl_mobile.png"
                class="mobile"
              />
            </figure>
          </div>
        </div>

        <br /><br />
        <br /><br />
        <br /><br />
        <span class="tag">HTML</span><span class="tag">CSS</span
        ><span class="tag">Foundation</span><span class="tag">JS</span
        ><span class="tag">JQuery</span> <br /><br />
        <br /><br />
      </div>
    </div>
  </section>
  <app-footer></app-footer>
</div>
