<div class="container-fluid">
  <app-header></app-header>
  <section class="section background">
    <div class="row">
      <div class="col-sm-12 text-center">
        <h1 class="text-center section_title">Project Time &amp; Cost, LLC</h1>
        <hr />
        <br />
        <p>
          PT&amp;C only had one application and it was an online portal with
          mini applications inside. They used a Bootstrap template called
          Inspinia and one of the things that I did was edit the master SASS/CSS
          files to give it a different look. I used a tool called Koala to
          compile the SASS into the master CSS file. The main things that were
          changed: fonts and colors. I also built a large percentage of the ECP
          application, with my co-workers buiding the rest. Every project was a
          team effort, and I gained a lot of knowldge because of that.
        </p>

        <br /><br />
        <div class="row">
          <div class="col-sm-6 text-center hover01">
            <figure>
              <img
                src="../../../../assets/images/projects/ptc/home.png"
                style="width: 100%"
              />
            </figure>
          </div>
          <div class="col-sm-6 text-center hover01">
            <figure>
              <img
                src="../../../../assets/images/projects/ptc/grid.png"
                style="width: 100%"
              />
            </figure>
          </div>
        </div>
        <br />
        <br />
        <div class="row">
          <div class="col-sm-6 text-center hover01">
            <figure>
              <img
                src="../../../../assets/images/projects/ptc/ecp.png"
                style="width: 100%"
              />
            </figure>
          </div>
          <div class="col-sm-6 text-center hover01">
            <figure>
              <img
                src="../../../../assets/images/projects/ptc/ecp form.png"
                style="width: 100%"
              />
            </figure>
          </div>
        </div>
        <br />
        <br />
        <div class="row">
          <div class="col-sm-3"></div>
          <div class="col-sm-6 text-center hover01">
            <figure>
              <img
                src="../../../../assets/images/projects/ptc/file.png"
                style="width: 100%"
              />
            </figure>
          </div>
          <div class="col-sm-3"></div>
        </div>
        <br />
        <br />
        <div class="row">
          <div class="col-sm-12 text-center">
            <span class="tag">HTML</span><span class="tag">CSS</span
            ><span class="tag">JS</span><span class="tag">ASP.NET MVC</span
            ><span class="tag">C#</span><span class="tag">LINQ</span>
            <span class="tag">EF</span><span class="tag">SQL</span>
          </div>
        </div>
        <br />
        <br />
        <br />
      </div>
    </div>
  </section>
  <app-footer></app-footer>
</div>
