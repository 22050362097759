<header class="background section">
  <div class="row">
    <div class="col-sm-2"></div>
    <div class="col-sm-8 text-center">
      <a [routerLink]="['/']"><img src="../../../assets/images/new_logo.png" /></a>
      <img
        class="profile-image img-fluid rounded-circle"
        src="../../../assets/images/profile.jpg"
      />
      <div class="row">
        <div class="col-sm-12 text-center">
          <h4>
            <fa-icon [icon]="faMapPin" style="color: #ae2a12"></fa-icon>
            Atlanta, GA
          </h4>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 text-center">
          <a href="http://github.com/tmott13" target="_blank" class="icon">
            <fa-icon [icon]="faGithub"></fa-icon
          ></a>
          &nbsp;&nbsp;
          <a
            href="http://www.linkedin.com/in/tmott13"
            target="_blank"
            class="icon"
            ><fa-icon [icon]="faLinkedIn"></fa-icon
          ></a>
          &nbsp;&nbsp;
          <a href="https://medium.com/@tmott13" target="_blank" class="icon"
            ><fa-icon [icon]="faMedium"></fa-icon
          ></a>
          &nbsp;&nbsp;
          <a href="http://twitter.com/tmott13" target="_blank" class="icon"
            ><fa-icon [icon]="faTwitter"></fa-icon
          ></a>
          &nbsp;&nbsp;
          <a href="https://dev.to/tmott13" target="_blank" class="icon"
            ><fa-icon [icon]="faDev"></fa-icon
          ></a>
        </div>
      </div>
    </div>
    <div class="col-sm-2"></div>
  </div>
</header>
