<div class="container-fluid">
  <app-header></app-header>
  <section class="section background">
    <div class="row">
      <div class="col-sm-12 text-center">
        <h1 class="text-center section_title">PowWow HR</h1>
        <hr />
        <br />
        <p>
          I took on the task of re-building the PowWow HR website during the
          first week of starting. I quickly noticed that the old website was not
          responsive and used a bulky template. I re-designed and re-built in
          Foundation using pure HTML/CSS/Javascript. They recently got bought
          out by a larger company but the website is still live. I didn't make
          any significant contributions to the internal web application once you
          log in but there was some other projects I worked on while there.
          Contact me for more information.
        </p>
        <p>
          Check out the site here:
          <a href="https://www.webhris.com/" target="_blank"
            >https://www.webhris.com/</a
          >
        </p>
        <br /><br />
        <div class="row">
          <div class="col-sm-12 text-center">
            <h3>Monitor</h3>
            <hr />
          </div>
        </div>
        <br /><br />
        <div class="row">
          <div class="col-sm-12 text-center hover01">
            <figure>
              <img
                src="../../../../assets/images/projects/powwow/pw_monitor.png"
                class="monitor"
              />
            </figure>
          </div>
        </div>
        <br /><br />
        <br /><br />
        <br /><br />
        <br /><br />
        <div class="row">
          <div class="col-sm-12 text-center">
            <h3>Laptop</h3>
            <hr />
          </div>
        </div>
        <br /><br />
        <div class="row">
          <div class="col-sm-12 text-center hover01">
            <figure>
              <img
                src="../../../../assets/images/projects/powwow/pw_laptop.png"
                class="laptop"
              />
            </figure>
          </div>
        </div>
        <br /><br />
        <br /><br />
        <br /><br />
        <br /><br />
        <div class="row">
          <div class="col-sm-12 text-center">
            <h3>Tablet</h3>
            <hr />
          </div>
        </div>
        <br /><br />
        <div class="row">
          <div class="col-sm-12 text-center hover01">
            <figure>
              <img
                src="../../../../assets/images/projects/powwow/pw_tablet.png"
                class="tablet"
              />
            </figure>
          </div>
        </div>
        <br /><br />
        <br /><br />
        <br /><br />
        <br /><br />
        <div class="row">
          <div class="col-sm-12 text-center">
            <h3>Phone</h3>
            <hr />
          </div>
        </div>
        <br /><br />
        <div class="row">
          <div class="col-sm-12 text-center hover01">
            <figure>
              <img
                src="../../../../assets/images/projects/powwow/pw_mobile.png"
                class="mobile"
              />
            </figure>
          </div>
        </div>
        <br /><br />
        <br /><br />
        <br /><br />

        <span class="tag">HTML</span><span class="tag">CSS</span
        ><span class="tag">Foundation</span><span class="tag">JS</span
        ><span class="tag">JQuery</span>

        <br /><br />
        <br /><br />
      </div>
    </div>
  </section>
  <app-footer></app-footer>
</div>
