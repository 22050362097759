<div class="container-fluid">
  <app-header></app-header>
  <section class="section background">
    <div class="row">
      <div class="col-sm-12 text-center">
        <h1 class="text-center section_title">On The Go</h1>
        <hr />
        <br />
        <p>
          On The Go is one of BreakAway Loyalty's web applications for a client. We
          re-designed the entire application and I built the landing page for
          the application. We hired a web designer on a contract to design the
          mock-ups.
        </p>
        <p>
          Check out the site here:
          <a href="http://www.otgtravelgc.com/" target="_blank"
            >www.otgtravelgc.com</a
          >
        </p>
        <br /><br />
        <div class="row">
          <div class="col-sm-12 text-center">
            <h3>Monitor</h3>
            <hr />
          </div>
        </div>
        <br /><br />
        <div class="row">
          <div class="col-sm-12 text-center hover01">
            <figure>
              <img src="../../../../assets/images/projects/otg/otg_monitor.png" class="monitor" />
            </figure>
          </div>
        </div>
        <br /><br />
        <br /><br />
        <br /><br />
        <br /><br />
        <div class="row">
          <div class="col-sm-12 text-center">
            <h3>Laptop</h3>
            <hr />
          </div>
        </div>
        <br /><br />
        <div class="row">
          <div class="col-sm-12 text-center hover01">
            <figure>
              <img src="../../../../assets/images/projects/otg/otg_laptop.png" class="laptop" />
            </figure>
          </div>
        </div>
        <br /><br />
        <br /><br />
        <br /><br />
        <br /><br />
        <div class="row">
          <div class="col-sm-12 text-center">
            <h3>Tablet</h3>
            <hr />
          </div>
        </div>
        <br /><br />
        <div class="row">
          <div class="col-sm-12 text-center hover01">
            <figure>
              <img src="../../../../assets/images/projects/otg/otg_tablet.png" class="tablet" />
            </figure>
          </div>
        </div>
        <br /><br />
        <br /><br />
        <br /><br />
        <br /><br />
        <div class="row">
          <div class="col-sm-12 text-center">
            <h3>Phone</h3>
            <hr />
          </div>
        </div>
        <br /><br />
        <div class="row">
          <div class="col-sm-12 text-center hover01">
            <figure>
              <img src="../../../../assets/images/projects/otg/otg_mobile.png" class="mobile" />
            </figure>
          </div>
        </div>
        <br /><br />
        <br /><br />
        <br /><br />
        <span class="tag">HTML</span><span class="tag">CSS</span
        ><span class="tag">Bootstrap</span><span class="tag">JS</span
        ><span class="tag">JQuery</span> <br /><br />
        <br /><br />
      </div>
    </div>
  </section>
  <app-footer></app-footer>
</div>
